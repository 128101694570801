import { EditOutlined, FlagOutlined, LeftOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import firebase from 'firebase/compat/app';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { setLocale } from '../../actions/app.actions';
import { logout } from '../../actions/signIn.actions';

const { SubMenu } = Menu;
const { Header, Content, Footer } = Layout;

class PageLayout extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.setLocaleLang = this.setLocaleLang.bind(this);
    }

    handleLogoutClick() {
        const { history: { push }, logout: handleLogout } = this.props;
        handleLogout(push);
    }

    handleChangePassword() {
        const { history: { push } } = this.props;
        push('/change-password');
    }

    setLocaleLang(lang) {
        const { setLocale: setLocaleAction } = this.props;

        return () => setLocaleAction(lang);
    }

    render() {
        const loggedIn = localStorage.getItem('isLoggin');
        const { children, goBack, history: { action, push }, intl, noLangIcons, role, title } = this.props;

        const remoteConfig = firebase.remoteConfig();

        return (
            <Layout className="Layout-container">
                <Header className={`header ${window.location.hostname.includes('staging') || window.location.hostname.includes('localhost') ? 'red' : ''}`}>
                    {null !== goBack && (
                        <Menu className="navigator">
                            <Menu.Item key="back" onClick={() => (action === 'PUSH' ? goBack() : push('/hello'))}>
                                <LeftOutlined />
                            </Menu.Item>
                        </Menu>
                    )}
                    <h1 className="main_title">{title}</h1>
                    <Menu mode="horizontal">
                        { !noLangIcons && (
                            <SubMenu key="SubMenu1" title={<FlagOutlined />}>
                                <Menu.Item key="enLang" onClick={this.setLocaleLang('en')}>
                                    🇬🇧 English
                                </Menu.Item>
                                <Menu.Item key="esLang" onClick={this.setLocaleLang('es')}>
                                    🇪🇸 Español
                                </Menu.Item>
                                <Menu.Item key="frLang" onClick={this.setLocaleLang('fr')}>
                                    🇫🇷 Français
                                </Menu.Item>
                                <Menu.Item key="itLang" onClick={this.setLocaleLang('it')}>
                                    🇮🇹 Italiano
                                </Menu.Item>
                                <Menu.Item key="ptLang" onClick={this.setLocaleLang('pt')}>
                                    🇵🇹 Português
                                </Menu.Item>
                            </SubMenu>
                        )}
                        {loggedIn && (
                            <SubMenu key="SubMenu2" title={<UserOutlined />}>
                                <Menu.Item key="logout" onClick={this.handleLogoutClick}>
                                    <PoweroffOutlined />
                                    <FormattedMessage id="menu_logout" />
                                </Menu.Item>
                                <Menu.Item
                                    key="changePassword"
                                    onClick={this.handleChangePassword}
                                >
                                    <EditOutlined />
                                    <FormattedMessage id="menu_change_password" />
                                </Menu.Item>
                            </SubMenu>
                        )}
                    </Menu>
                </Header>
                <Content className="content">
                    {children}
                </Content>
                <Footer className="footer">
                    © Ventilairsec 2019
                    <div className="cycle">
                        {role === 'admin' && (
                            <>
                                <span>Code particulier : {remoteConfig.getString('password_cycle')}</span>
                                <span>Code SAV : {remoteConfig.getString('sav_password_cycle')}</span>
                                <span>Code SMS : {remoteConfig.getString('sms_code')}</span>
                            </>
                        )}
                    </div>
                    <Link to={`/privacy/${intl.locale}`}>
                        <FormattedMessage id="cgu" />
                    </Link>
                    <Link to={`/gprd/${intl.locale}`}>
                        <FormattedMessage id="gprd" />
                    </Link>
                </Footer>
            </Layout>
        );
    }
}

PageLayout.propTypes = {
    children: PropTypes.node.isRequired,
    goBack: PropTypes.func,
    history: PropTypes.shape({
        action: PropTypes.string,
        push: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
    logout: PropTypes.func.isRequired,
    noLangIcons: PropTypes.bool,
    role: PropTypes.string,
    setLocale: PropTypes.func.isRequired,
    title: PropTypes.string,
};

PageLayout.defaultProps = {
    goBack: null,
};

const mapStateToProps = ({ signIn: { loggedIn, role } }) => ({ loggedIn, role });

export default withRouter(connect(mapStateToProps, { setLocale, logout })(injectIntl(PageLayout)));
