/* eslint-disable no-console */
import React from 'react';

class Stores extends React.Component {
    constructor(props) {
        super(props);
        this.state = { os: '' };
    }

    componentDidMount() {
        const uA = navigator.userAgent || window.opera;
        if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) {
            this.setState({ os: 'iOS' });
            window.location.href = 'https://apps.apple.com/fr/app/vmi/id1454495331';
        } else {
            let i; const os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
            for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA) && os[i] === 'Android') {
                this.setState({ os: 'Android' });
                window.location.href = 'https://play.google.com/store/apps/details?id=com.ventilairsec.ventilairsecinstallateur';
            }
        }
    }

    render() {
        const { os } = this.state;

        return <h1>Redirecting ({os})...</h1>;
    }
}

export default Stores;
