import './BdgGenerator.scss';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { getBdgValues } from '../../actions/bdg.actions';

class BdgGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bdgVal: {},
        };
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props;
        getBdgValues(id).then((snapshot) => {
            this.setState({ bdgVal: snapshot.data() });
        });
    }

    render() {
        const {
            bdgVal: {
                box,
                boxType,
                byPassId,
                companyAddress1,
                companyAddress2,
                companyMail,
                companyName,
                companyPhone,
                constructionType,
                hasHydro,
                hasSolar,
                info,
                installDate,
                installerCode,
                isPrestige,
                lang,
                maintenanceContract,
                signatureUrl,
                vmiRef,
            },
        } = this.state;

        const i18n = require('../../translations/warranty/i18n.warranty.json'); // eslint-disable-line import/no-dynamic-require,global-require
        const newLang = lang || 'fr';

        return (
            <div className="root">

                <section className="page">

                    <h1>{i18n.title[newLang]}</h1>

                    <h4>{i18n.title_install[newLang]} : {companyName}</h4>

                    {(info || '').length > 0 && (
                        <p className="info">{info}</p>
                    )}

                    <div className="address">
                        <p>{companyAddress1}</p>
                        <p>{companyAddress2}</p>
                        <p>{companyPhone}</p>
                        <p>{companyMail}</p>
                    </div>

                    <table className="tableInfo">
                        <tbody>

                            <tr>
                                <th align="center" colSpan="2">{i18n.installer_code[newLang]}</th>
                                <th align="center">{i18n.ref[newLang]}</th>
                                <th align="center" colSpan="2">{i18n.code[newLang]}</th>
                            </tr>
                            <tr>
                                <td align="center" colSpan="2">{installerCode}</td>
                                <td align="center">{vmiRef}</td>
                                <td align="center" colSpan="2">{box}</td>
                            </tr>

                            {false === isPrestige && boxType !== 3 && boxType !== 2 && (
                                boxType === 1 ? (
                                    <>
                                        <tr>
                                            <th align="center" rowSpan="2">{i18n.coupled_vmi[newLang]}</th>
                                            <th align="right">{i18n.bypass[newLang]}</th>
                                            <td colSpan="3">{byPassId || i18n.no[newLang]}</td>
                                        </tr>
                                        <tr>
                                            <th align="right">{i18n.hydror[newLang]}</th>
                                            <td colSpan="3">{hasHydro ? i18n.yes[newLang] : i18n.no[newLang]}</td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr>
                                        <th align="right" colSpan="2">{i18n.bypass[newLang]}</th>
                                        <td colSpan="3">{byPassId || i18n.no[newLang]}</td>
                                    </tr>
                                )
                            )}

                            {isPrestige && installDate && (
                                <>
                                    <tr>
                                        <th align="center" rowSpan="2">{i18n.coupled_vmi[newLang]}</th>
                                        <th align="right">{i18n.has_hydro[newLang]}</th>
                                        <td colSpan="3">{hasHydro ? i18n.yes[newLang] : i18n.no[newLang]}</td>
                                    </tr>
                                    <tr>
                                        <th align="right">{i18n.has_solar[newLang]}</th>
                                        <td colSpan="3">{hasSolar ? i18n.yes[newLang] : i18n.no[newLang]}</td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <th align="right" colSpan="2">{i18n.date[newLang]}</th>
                                <td>{new Date((installDate || {}).seconds * 1000).toLocaleDateString(lang)}</td>
                                <th align="right">{i18n.contract[newLang]}</th>
                                <td>{maintenanceContract ? i18n.yes[newLang] : i18n.no[newLang]}</td>
                            </tr>

                            {constructionType && (
                                <tr>
                                    <th align="right" colSpan="2">Type de construction</th>
                                    <td colSpan="3">{constructionType === 'new' ? 'Neuf' : 'Rénovation'}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>

                    <div className="userSignature">
                        <span>{i18n.signature[newLang]}</span>
                        <img alt="signature" src={signatureUrl} />
                    </div>

                    <div className="legal-div">
                        <p className="legals">{i18n.text1[newLang]}</p>
                        <p className="legals legalsRespect">{i18n.text3[newLang]}</p>
                    </div>

                    <div className="contact">
                        <img alt="logo" className="bdg-logo" src={`${process.env.PUBLIC_URL}/assets/img/logo.svg`} />
                        <small className="address">
                            16 rue des imprimeurs<br />
                            44220 COUËRON - FRANCE<br />
                            Tél. +33 (0)2 40 04 19 44 - Fax : +33 (0)2 40 03 96 11<br />
                            E-mail : contact@vmi-technologies.com
                        </small>
                    </div>

                </section>

                {constructionType === 'new' && (

                    <section className="page">

                        <h1>Conformité de l&apos;installation</h1>

                        <div className="requirements">
                            <p><Checkbox checked>La VMI est accessible ainsi que le filtre</Checkbox></p>
                            <p><Checkbox checked>Le ventilateur fonctionne</Checkbox></p>
                            <p><Checkbox checked>Le raccordement électrique est conforme (ligne dédiée avec disjoncteur 16A)</Checkbox></p>
                            <p><Checkbox checked>La commande de contrôle est installée et fonctionnelle</Checkbox></p>
                            <p><Checkbox checked>La prise d&apos;air est bien dimensionnée et protégée de la pluie</Checkbox></p>
                            <p><Checkbox checked>Le caisson est correctement gainé (réseau étanche et non contraint)</Checkbox></p>
                            <p><Checkbox checked>Les conduits en dehors du volume chauffé sont isolés</Checkbox></p>
                            <p><Checkbox checked>Présence d&apos;une ou plusieurs bouches de soufflage (accessibles, bien installées)</Checkbox></p>
                            <p><Checkbox checked>Présence d&apos;une sortie d&apos;air dans toutes les pièces (à tirage naturel ou autoréglable, bien calibrée)</Checkbox></p>
                            <p><Checkbox checked>Toutes les portes sont détalonnées (1cm sauf en cuisine 2 cm)</Checkbox></p>
                        </div>

                        <div className="contact">
                            <img alt="logo" className="bdg-logo" src={`${process.env.PUBLIC_URL}/assets/img/logo.svg`} />
                            <small className="address">
                                16 rue des imprimeurs<br />
                                44220 COUËRON - FRANCE<br />
                                Tél. +33 (0)2 40 04 19 44 - Fax : +33 (0)2 40 03 96 11<br />
                                E-mail : contact@vmi-technologies.com
                            </small>
                        </div>

                    </section>

                )}

            </div>
        );
    }
}

BdgGenerator.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default BdgGenerator;
