import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import BdgGenerator from './components/bdg-generator/BdgGenerator';
import ChangePassword from './components/change-password/ChangePassword';
import CompanyManagement from './components/company/CompanyManagement';
import Documents from './components/documents/Documents';
import FirstConnection from './components/first-connection/FirstConnection';
import ForgotPassword from './components/forgot-password/ForgotPassword';
import Gprd from './components/gprd/Gprd';
import Hello from './components/hello/Hello';
import Login from './components/login/Login';
import Privacy from './components/privacy/Privacy';
import Stores from './components/stores/Stores';
import Support from './components/support/Support';
import UploadFirmware from './components/upload-firmware/UploadFirmware';
import UserManagement from './components/user/UserManagement';
import checkLang from './hoc/checkLang';
import requireAuth from './hoc/requireAuth';
import requireNotLogin from './hoc/requireNotLogin';

const history = createBrowserHistory();

const CustomRouter = ({ intl }) => {
    document.title = intl.formatMessage({ id: 'app_title' });

    return (
        <Router history={history}>
            <Switch>
                <Route component={BdgGenerator} path="/bdg-generator/:id" />
                <Route component={Stores} path="/stores" />
                <Route component={Support} path="/support" />
                <Route component={checkLang(Gprd)} path="/gprd/:lang" />
                <Route component={checkLang(Privacy)} path="/privacy/:lang" />
                <Route component={requireAuth(ChangePassword, 'change-password')} path="/change-password" />
                <Route component={requireAuth(CompanyManagement, 'company')} path="/company" />
                <Route component={requireAuth(Documents, 'documents')} path="/documents" />
                <Route component={requireAuth(Hello)} path="/hello" />
                <Route component={requireAuth(UploadFirmware, 'upload-firmware')} path="/upload-firmware" />
                <Route component={requireAuth(UserManagement, 'user')} path="/user" />
                <Route component={requireNotLogin(FirstConnection)} path="/first-connection" />
                <Route component={requireNotLogin(ForgotPassword)} path="/forgot-password" />
                <Route component={requireNotLogin(Login)} path="/" />
                <Redirect from="*" to="/" />
            </Switch>
        </Router>
    );
};

CustomRouter.propTypes = {
    intl: PropTypes.any.isRequired,
};

export default injectIntl(CustomRouter);
