import { Select } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { deleteUser, getOneUser, getUsersByCompany, updateUser } from '../../actions/user.actions';
import EditableTable from '../table/EditableTable';
import { COUNTRIES_TABLE } from './countries';
import { ROLES_TABLE } from './roles';

const { Option } = Select;

const tableName = 'user';

const UserTable = ({ getOneUser: getUsersAction, intl, role, users }) => {
    const roleLvl = (ROLES_TABLE.filter((cRole) => cRole.name === role)[0] || {}).level;
    const agenciesAlreadyPushed = [];
    const agenciesArray = [];

    users.forEach((user) => {
        if (user.agency) if (!agenciesAlreadyPushed.includes(user.agency)) {
            agenciesAlreadyPushed.push(user.agency);
            agenciesArray.push({
                text: user.agency,
                value: user.agency,
            });
        }
    });

    const columns = [
        {
            dataIndex: 'id',
            ellipsis: true,
            title: <FormattedMessage id={`${tableName}_userName`} />,
            type: 'text',
        },
        {
            dataIndex: 'first_name',
            editable: true,
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.first_name.localeCompare(b.first_name),
            title: <FormattedMessage id={`${tableName}_firstName`} />,
            type: 'text',
        },
        {
            dataIndex: 'last_name',
            editable: true,
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.last_name.localeCompare(b.last_name),
            title: <FormattedMessage id={`${tableName}_lastName`} />,
            type: 'text',
        },
        {
            dataIndex: 'role',
            ellipsis: true,
            filterMultiple: false,
            filters: [
                {
                    text: <FormattedMessage id="roles_adminClient" />,
                    value: 'adminClient',
                },
                {
                    text: <FormattedMessage id="roles_adminAgency" />,
                    value: 'adminAgency',
                },
                {
                    text: <FormattedMessage id="roles_installer" />,
                    value: 'installer',
                },
                {
                    text: <FormattedMessage id="roles_ged" />,
                    value: 'ged',
                },
            ],
            onFilter: (value, record) => record.nativeRole === value,
            selectoptions: ROLES_TABLE.filter((cRole) => cRole.level < roleLvl).map((cRole) => <Option key={cRole.name}><FormattedMessage id={`roles_${cRole.name}`} /></Option>),
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.role.localeCompare(b.role),
            title: <FormattedMessage id={`${tableName}_role`} />,
            type: 'select',
            width: 238,
        },
        {
            align: 'center',
            dataIndex: 'country',
            editable: true,
            onFilter: (value, record) => record.country === value,
            render: (country) => {
                if (country) {
                    const { fullName } = COUNTRIES_TABLE.filter((country2) => country2.prefix === country)[0];

                    return <img alt={country} className="country" src={`https://cdn.countryflags.com/thumbs/${fullName}/flag-3d-500.png`} />;
                }

                return null;
            },
            selectoptions: COUNTRIES_TABLE.sort((a, b) => intl.formatMessage({ id: `country_${b.prefix}` }) < intl.formatMessage({ id: `country_${a.prefix}` })).map((country) => <Option key={country.prefix}><img alt={country.prefix} className="country" src={`https://cdn.countryflags.com/thumbs/${country.fullName}/flag-3d-500.png`} /></Option>),
            title: <FormattedMessage id={`${tableName}_country`} />,
            type: 'select',
            width: 82,
        },
        {
            dataIndex: 'phone',
            editable: 'admin' === role,
            ellipsis: true,
            title: <FormattedMessage id={`${tableName}_phone`} />,
            type: 'text',
            width: 164,
        },
        {
            dataIndex: ['company', 'name'],
            ellipsis: true,
            title: <FormattedMessage id={`${tableName}_companyId`} />,
            type: 'select',
        },
        {
            dataIndex: 'agency',
            editable: 'admin' === role || 'adminClient' === role,
            ellipsis: true,
            filterMultiple: false,
            filters: agenciesArray.sort((a, b) => a.value.localeCompare(b.value)),
            onFilter: (value, record) => record.agency === value,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => (a.agency || '').localeCompare(b.agency || ''),
            title: <FormattedMessage id="agency" />,
            type: 'text',
        },
        {
            dataIndex: 'info',
            editable: 'admin' === role || 'adminClient' === role || 'adminAgency' === role,
            ellipsis: true,
            title: <FormattedMessage id="info" />,
            type: 'text',
        },
    ];

    const messages = {
        deleteMessage: 'deleteUser_message',
        deleteSuccess: 'message_success_delete_user',
        updateSuccess: 'message_success_update_user',
    };

    return (
        <EditableTable
            columns={columns}
            dataSource={users}
            deleteFunction={deleteUser}
            messages={messages}
            refreshFunction={getUsersAction}
            tableName={tableName}
            updateFunction={updateUser}
        />
    );
};

UserTable.propTypes = {
    getOneUser: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
    role: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = ({ signIn: { agency, role }, users: { users } }) => ({ agency, role, users });

export default connect(mapStateToProps, { getOneUser, getUsersByCompany })(injectIntl(UserTable));
