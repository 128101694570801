export const ROLES_TABLE = [
    {
        hoc: ['change-password', 'company', 'documents', 'upload-firmware', 'user'],
        level: 4,
        name: 'admin',
    },
    {
        hoc: [],
        level: 4,
        name: 'production',
    },
    {
        hoc: [],
        level: 4,
        name: 'publicInstaller',
    },
    {
        hoc: ['change-password', 'documents'],
        level: 3,
        name: 'ged',
    },
    {
        hoc: ['change-password', 'company', 'user'],
        level: 2,
        name: 'adminClient',
    },
    {
        hoc: ['change-password', 'user'],
        level: 1,
        name: 'adminAgency',
    },
    {
        hoc: [],
        level: 0,
        name: 'installer',
    },
];

export default ROLES_TABLE;
