import { FormattedMessage } from 'react-intl';

const rules = {
    address: [
        { required: true, message: <FormattedMessage id="address_required" /> },
    ],
    clientAccount: [
        { pattern: /^[a-zA-Z0-9 ]{2,20}$/, message: <FormattedMessage id="client_account_valid" /> },
        { required: true, message: <FormattedMessage id="client_account_required" /> },
    ],
    city: [
        { required: true, message: <FormattedMessage id="city_required" /> },
    ],
    company: [
        { required: true, message: <FormattedMessage id="user_companyId_required" /> },
    ],
    country: [
        { required: true, message: <FormattedMessage id="country_required" /> },
    ],
    distributor: [
        { required: true, message: <FormattedMessage id="distributor_required" /> },
    ],
    firmware: [
        { pattern: /^[0-9]{1}.[0-9]{1}.[0-9]{3}$/, message: 'Format x.x.xxx requis' },
        { required: true, message: <FormattedMessage id="firmware_password_required" /> },
    ],
    firstName: [
        { required: true, message: <FormattedMessage id="firstname_required" /> },
    ],
    lastName: [
        { required: true, message: <FormattedMessage id="name_required" /> },
    ],
    mail: [
        { required: true, message: <FormattedMessage id="email_required" /> },
        { type: 'email', message: <FormattedMessage id="email_valid" /> },
    ],
    max_users: [
        { required: true, message: <FormattedMessage id="max_users_required" /> },
    ],
    name: [
        { required: true, message: <FormattedMessage id="name_required" /> },
    ],
    password: [
        { min: 8, message: <FormattedMessage id="login_password_valid_min" /> },
        { required: true, message: <FormattedMessage id="login_password_required" /> },
    ],
    phone: [
        { pattern: /^[0-9+]{6,13}$/, message: <FormattedMessage id="phone_valid" /> },
        { required: true, message: <FormattedMessage id="phone_required" /> },
    ],
    role: [
        { required: true, message: <FormattedMessage id="role_required" /> },
    ],
    siren: [
        { pattern: /^[A-Z0-9]{5,}$/, message: <FormattedMessage id="siren_valid" /> },
        { required: true, message: <FormattedMessage id="siren_required" /> },
    ],
    title: [
        { required: true, message: <FormattedMessage id="title_required" /> },
    ],
    url: [
        { pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, message: <FormattedMessage id="url_valid" /> },
        { required: true, message: <FormattedMessage id="url_required" /> },
    ],
    username: [
        { min: 5, message: <FormattedMessage id="userName_valid" /> },
        { required: true, message: <FormattedMessage id="login_username_required" /> },
    ],
    zip_code: [
        { pattern: /^[a-zA-Z0-9-]{2,11}$/, message: <FormattedMessage id="zip_code_valid" /> },
        { required: true, message: <FormattedMessage id="zip_code_required" /> },
    ],
};

export default rules;
