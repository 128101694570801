import './Support.scss';
import { Form } from '@ant-design/compatible';
import { DeleteOutlined, YoutubeOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, message, List, Modal, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { deleteVideo, getVideos, setVideoLink } from '../../actions/video.actions';
import validationRules from '../../utils/validationRules';
import PageLayout from '../layout/PageLayout';

class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
        this.showModal = this.showModal.bind(this);
        this.showYoutubeModal = this.showYoutubeModal.bind(this);
    }

    componentDidMount() {
        const { getVideos: getVideosActions } = this.props;
        getVideosActions();
    }

    showModal(firebaseId, type) {
        const { getVideos: getVideosActions, intl } = this.props;

        Modal.confirm({
            cancelText: intl.formatMessage({ id: 'global_no' }),
            okButtonProps: { style: { backgroundColor: '#57368c' } },
            okText: intl.formatMessage({ id: 'global_yes' }),
            okType: 'danger',
            title: intl.formatMessage({ id: `support_delete_question_${type}` }),
            onOk() {
                deleteVideo(firebaseId).then(() => {
                    message.success(intl.formatMessage({ id: `message_success_delete_${type}` }));
                    getVideosActions();
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                });
            },
        });
    }

    showYoutubeModal(show) {
        return () => {
            this.setState({ visible: show });
        };
    }

    render() {
        const {
            form: { getFieldDecorator, validateFields },
            getVideos: getVideosActions,
            history: { goBack },
            intl,
            role,
            videos,
        } = this.props;
        const { visible } = this.state;

        return (
            <PageLayout goBack={goBack} title={intl.formatMessage({ id: 'hello_support_video' })}>

                {'admin' === role

              && (
                  <div className="upload-buttons">

                      <Button className="upload-btn" onClick={this.showYoutubeModal(true)}>
                          <FormattedMessage id="support_button_link" />
                          <YoutubeOutlined />
                      </Button>

                      <Modal
                          cancelText={intl.formatMessage({ id: 'button_cancel' })}
                          okText={intl.formatMessage({ id: 'support_add_button' })}
                          onCancel={this.showYoutubeModal(false)}
                          onOk={() => {
                              validateFields((err, values) => {
                                  if (!err) setVideoLink(values).then((result) => {
                                      message.success(intl.formatMessage({ id: `message_success_${result}` }));
                                  }).catch((error) => {
                                      const translateString = `message_fail_${error.message}`;
                                      if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                                      else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                                  }).finally(() => {
                                      getVideosActions();
                                      this.showYoutubeModal(false)();
                                  });
                              });
                          }}
                          open={visible}
                          title={intl.formatMessage({ id: 'support_insert_question' })}
                      >
                          <Form name="youtube-form">
                              <FormItem>
                                  {getFieldDecorator('title', {
                                      rules: validationRules.title,
                                  })(
                                      <Input
                                          placeholder={intl.formatMessage({ id: 'support_modal_title' })}
                                      />,
                                  )}
                              </FormItem>
                              <FormItem>
                                  {getFieldDecorator('url', {
                                      rules: validationRules.url,
                                  })(
                                      <Input
                                          placeholder={intl.formatMessage({ id: 'support_modal_url' })}
                                      />,
                                  )}
                              </FormItem>
                          </Form>

                      </Modal>

                  </div>
              )}

                <List
                    className="upload-list"
                    dataSource={videos}
                    header={<div><p><FormattedMessage id="support_header_videos" /></p><YoutubeOutlined /></div>}
                    renderItem={(video) => (
                        <List.Item>
                            <Button onClick={() => window.open(video.url, '_blank')} type="link">{video.title}</Button>
                            {'admin' === role && (
                                <Button onClick={() => this.showModal(video.firebaseId, 'video')} danger>
                                    <DeleteOutlined />
                                </Button>
                            )}
                        </List.Item>
                    )}
                    size="large"
                    bordered
                />

            </PageLayout>
        );
    }
}

Support.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
    getVideos: PropTypes.func.isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
    role: PropTypes.string,
    videos: PropTypes.array.isRequired,
};

const ModalForm = Form.create({ name: 'modal' })(Support);
const mapStateToProps = ({ signIn: { role, userName }, videos: { videos } }) => ({ role, userName, videos });

export default connect(mapStateToProps, { getVideos })(injectIntl(ModalForm));
