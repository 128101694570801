import './EditableTable.scss';
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Modal, message, Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ROLES_TABLE } from '../user/roles';
import EditableCell from './EditableCell';
import EditableFormRow, { EditableContext } from './EditableRow';

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.actionsColumns = this.actionsColumns.bind(this);
        this.cancel = this.cancel.bind(this);
        this.getDeleteBtn = this.getDeleteBtn.bind(this);
        this.isEditing = this.isEditing.bind(this);
        this.showModal = this.showModal.bind(this);
        this.state = { editingKey: '' };
    }

    getDeleteBtn(record) {
        const { company, role } = this.props;
        if (
            ROLES_TABLE.find((cRole) => cRole.name === role).level > (ROLES_TABLE.find((cRole) => cRole.name === record.nativeRole) || { level: 10 }).level
            || (!record.role && record.firebaseId !== company)
        ) return (
            <Button onClick={() => this.showModal(record.firebaseId, record)} danger>
                <DeleteOutlined />
            </Button>
        );

        return null;
    }

    getEditBtn(record) {
        const { role } = this.props;
        if (ROLES_TABLE.find((cRole) => cRole.name === role).level > (ROLES_TABLE.find((cRole) => cRole.name === record.nativeRole) || { level: 2 }).level) return (
            <Button onClick={() => this.edit(record.firebaseId)}>
                <EditOutlined />
            </Button>
        );

        return null;
    }

    actionsColumns() {
        const { intl, tableName } = this.props;

        return [{
            align: 'center',
            dataIndex: 'actions',
            editable: false,
            title: intl.formatMessage({ id: 'global_action' }),
            width: tableName === 'company' ? 250 : 150,
            render: (_, record) => {
                const editable = this.isEditing(record);

                return (
                    <div className="actionButtons">
                        {editable ? (
                            <span>
                                <EditableContext.Consumer>
                                    {(form) => (
                                        <CheckCircleTwoTone
                                            className="validate-button"
                                            onClick={() => this.save(form, record.firebaseId)}
                                            twoToneColor="#52c41a"
                                        />
                                    )}
                                </EditableContext.Consumer>
                                <Popconfirm
                                    onConfirm={() => this.cancel(record.id)}
                                    title={<FormattedMessage id="edit_discard" />}
                                >
                                    <CloseCircleTwoTone
                                        className="cancel-button"
                                        twoToneColor="#eb2f96"
                                    />
                                </Popconfirm>
                            </span>
                        ) : (
                            this.getEditBtn(record)
                        )}
                        {this.getDeleteBtn(record)}
                    </div>
                );
            },
        }];
    }

    isEditing(record) {
        const { editingKey } = this.state;

        return record.firebaseId === editingKey;
    }

    cancel() {
        this.setState({ editingKey: '' });
    }

    save(form, firebaseId) {
        form.validateFields((err, values) => {
            if (!err) {
                const {
                    intl,
                    messages: { updateSuccess },
                    refreshFunction,
                    updateFunction,
                } = this.props;

                const values2 = values;
                if (!ROLES_TABLE.find((role) => role.name === values2.role)) delete values2.role;

                if (values2['company,name']) {
                    values2.company = values2['company,name'];
                    delete values2['company,name'];
                }

                updateFunction(firebaseId, values2).then(async () => {
                    message.success(intl.formatMessage({ id: updateSuccess }));
                    await refreshFunction(values2.max_users ? null : firebaseId);
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                }).finally(() => {
                    this.setState({ editingKey: '' });
                });
            }
        });
    }

    showModal(firebaseId, record) {
        const {
            deleteFunction,
            intl,
            messages: { deleteMessage, deleteSuccess, deleteMessageExtra },
            refreshFunction,
        } = this.props;
        let deleteMessageExtra2 = deleteMessageExtra;
        if ('adminAgency' === record.nativeRole) deleteMessageExtra2 = 'deleteCompany_message_extra';
        Modal.confirm({
            cancelText: intl.formatMessage({ id: 'global_no' }),
            content: deleteMessageExtra2 ? intl.formatMessage({ id: deleteMessageExtra2 }) : null,
            okText: intl.formatMessage({ id: 'global_yes' }),
            okType: 'danger',
            title: intl.formatMessage({ id: deleteMessage }),
            onOk() {
                deleteFunction(firebaseId, record).then(() => {
                    message.success(intl.formatMessage({ id: deleteSuccess }));
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                }).finally(async () => {
                    await refreshFunction(firebaseId);
                });
            },
        });
    }

    edit(id) {
        this.setState({ editingKey: id });
    }

    render() {
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        const { columns, dataSource, intl, loading, editableContent, tableName } = this.props;

        const realColumns = editableContent ? [...columns, ...this.actionsColumns()] : [...columns];
        const completeColumns = realColumns.map((col) => {
            if (!col.editable) return col;

            return {
                ...col,
                onCell: (record) => {
                    const record2 = record;
                    if (!record2.isTranslate && record2.role) {
                        record2.isTranslate = true;
                        record2.nativeRole = record2.role;
                        record2.role = intl.formatMessage({ id: `roles_${record2.nativeRole}` });
                    }

                    return {
                        record,
                        dataIndex: col.dataIndex,
                        editing: this.isEditing(record),
                        inputType: col.type,
                        selectoptions: col.selectoptions,
                    };
                },
            };
        });

        return (
            <Table
                className={tableName}
                columns={completeColumns}
                components={components}
                dataSource={dataSource}
                loading={loading}
                pagination={false}
                rowClassName="editable-row"
                rowKey="firebaseId"
                scroll={{ scrollToFirstRowOnChange: true, x: 1700, y: 'max-content' }}
                size="middle"
            />
        );
    }
}

EditableTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    company: PropTypes.string.isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.shape({ role: PropTypes.string })).isRequired,
    deleteFunction: PropTypes.func.isRequired,
    editableContent: PropTypes.bool,
    intl: PropTypes.any.isRequired,
    loading: PropTypes.bool,
    messages: PropTypes.shape({
        deleteMessage: PropTypes.string.isRequired,
        deleteMessageExtra: PropTypes.string,
        deleteSuccess: PropTypes.string.isRequired,
        updateSuccess: PropTypes.string.isRequired,
    }).isRequired,
    refreshFunction: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    updateFunction: PropTypes.func.isRequired,
};

EditableTable.defaultProps = {
    editableContent: true,
    loading: false,
};

const mapStateToProps = ({ signIn: { company, role } }) => ({ company, role });
export default connect(mapStateToProps)(injectIntl(EditableTable));
